
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,700;0,800;1,200;1,300;1,400;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@800&family=Josefin+Sans:wght@100&family=Nova+Round&display=swap');

$font-1 : 'Nova Round', cursive;
$font-2 : 'Dosis', sans-serif;
$font-3 : 'Josefin Sans', serif;
$font-4 : 'Nunito', cursive;

$color-1 : #FFC802;
$color-2 : #FF9E03;
$color-3 : #FF772D;
$color-4 : #4A473F;
$black : #1F1F1F;

$borderRadius : 25px;
$shadow : 0 1px 3px $black;
$minHeight : 95vh;

@mixin verticalCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

* , ::before , ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    position: relative;
}

body {
    margin: 1rem auto;
    max-width: 1200px;
    font-family: $font-1;
    color: $black;
}

button {
    cursor: pointer;
}

ul , li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: $black;
    cursor: pointer;
}

a:visited {
    color: $black;
}

.home , .knowledges , .portfolio , .contact {
    display: flex;

    @media screen and (max-width: 850px) {
        display: block !important;
    }
}

#root .homeContent , .knowledgesContent , .portfolioContent , .contactContent {
    margin-left: 0.4rem ;

    @media screen and (max-width: 850px) {
        width: 95% !important;
        margin: auto !important; 
    }
}

