.home {

  .homeContent {
    // background: linear-gradient(115deg, $color-1 20%, transparent 50%, transparent 100%);
    min-height: $minHeight;
    width: 100%;
    min-height: 500px;
    border-radius: $borderRadius;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: inherit;
      background: url('../../media/CV_Plan_1.jpg') no-repeat center/cover;
      z-index: -1;
      // filter: brightness(30%); */ 
      background-position-x: 100%;
    }

    .content {
      position: absolute;
      top: 20%;
      left: 13%;
      font-family: $font-1;

      h1 {
        font-size: 5rem;
        font-family: $font-4;
        color: $color-1;
        text-transform: uppercase;
        text-shadow: 3px 6px 2px $black;
      }

      h2 {
        font-size: 2.5rem;
        padding: 1.5rem 0 7rem;
        color: $color-1;
        text-shadow: 1px 4px 1px $black;
      }

      .pdf {

        a {
          font-size: 1.5rem;
          background: $color-3;
          padding: 1rem 2rem;
          border-radius: $borderRadius;
          box-shadow: 0 5px 1px rgb(15, 15, 15);
          transition: 0.2s ease;

          &:hover {
            background: $color-4;
            color: $color-1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px){
  .homeContent {
    margin: 0 !important;
  }
}

@media screen and (max-width:580px){
  .homeContent {
    height: 85vh !important;

    &:after {
      width: 100% !important;
    }
    .content {
      top: 10% !important;
      left: 5% !important;

      h2 {
        padding: 1.5rem 0 5rem !important;
      }
    }

    h1 {
      font-size: 3rem !important;
    }
    h2 {
      font-size: 1.5rem !important;
    }
  }
}